import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      breadcrumb: "الرئيسية",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
    },
  },
  {
    path: "/product/details/:id",
    name: "details",
    meta: {
      breadcrumb: "منتج",
    },
    component: function () {
      return import("../views/EstateView.vue");
    },
  },
  {
    path: "/auctions/:id",
    name: "auction",
    meta: {
      breadcrumb: "تفاصيل المزاد",
    },
    component: function () {
      return import("../views/AuctionView.vue");
    },
  },
  {
    path: "/auctions",
    name: "auctions",
    meta: {
      breadcrumb: "المزادات",
    },
    component: function () {
      return import("../views/AuctionsView.vue");
    },
  },

  {
    path: "/company_profile/:id",
    name: "company_profile",
    component: function () {
      return import("../views/CompanyProfileView.vue");
    },
  },


  {
    path: "/companies",
    name: "companies",
    meta: {
      breadcrumb: "الشركات",
    },
    component: function () {
      return import("../views/CompaniesView.vue");
    },
  },

  {
    path: "/contact_us",
    name: "contact_us",
    meta: {
      breadcrumb: "تواصل معنا",
    },
    component: function () {
      return import("../views/ContactUsView.vue");
    },
  },
  {
    path: "/company/:id",
    name: "company",
    component: function () {
      return import("../views/CompanyProfileView.vue");
    },
  },
  {
    path: "/terms",
    name: "Terms of Use",
    component: () => import("../views/TermsOfUseView.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
  },
  
  {
    path: "/login",
    name: "login",
    meta: {
      breadcrumb: "تسجيل الدخول",
    },
    component: function () {
      return import("../views/LoginView.vue");
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      breadcrumb: "نسيت كلمة السر",
    },
    component: function () {
      return import("../views/ForgetPassword.vue");
    },
  },

  {
    path: "/otp",
    name: "otp",
    meta: {
      breadcrumb: "OTP",
    },
    component: function () {
      return import("../components/Auth/OTPForm.vue");
    },
  },

  {
    path: "/new-password",
    name: "new-password",
    meta: {
      breadcrumb: "new-password",
    },
    component: function () {
      return import("../components/Auth/NewPassword.vue");
    },
  },
  {
    path: "/register/company",
    name: "company_resgister",
    component: function () {
      return import("../views/RegisterCompanyView.vue");
    },
  },

  {
    path: "/register/person",
    name: "person_register",
    component: function () {
      return import("../views/RegisterPersonView.vue");
    },
  },
 
  {
    path: "/client",
    name: "client",
    meta: { showBar: false, secured: true, breadcrumb: "منطقة العميل" },
    redirect: "/client/company-profile",
    component: function () {
      return import("../views/ClientView.vue");
    },
    children: [
      {
        name: "client-products",
        path: "products",
        meta: { breadcrumb: "المنتجات" },
        component: function () {
          return import("../components/Client/ProductsListComponent.vue");
        },
      },
      {
        name: "user-products",
        path: "my-auctions",
        meta: { breadcrumb: "مزاداتي" },
        component: function () {
          return import("../components/Client/MyAuctions.vue");
        },
      },
      {
        path: "invoice/:id",
        meta: {
          showBar:false,
          dontShowTop:true
        },
        name: "Invoice",
        component: () => import("../components/Invoice.vue"),
      },
      {
        path: "invoice",
        meta: {
          breadcrumb: "فواتيري"
        },
        name: "Invoices",
        component: () => import("../components/Invoices.vue"),
      },
      {
        name: "client-wallet",
        path: "wallet",
        meta: { breadcrumb: "محفظتي" },
        component: function () {
          return import("../components/Client/Wallet.vue");
        },
      },
      
      {
        path: "auctions",
        meta: { breadcrumb: "المزادات" },
        component: function () {
          return import("../components/Client/AuctionsContainer.vue");
        },
        children: [
          {
            name: "main",
            path: "",
            meta: { breadcrumb: "قائمة المزادات" },
            component: function () {
              return import("../components/Client/AuctionsListComponent.vue");
            },
          },

          {
            name: "auction details",
            path: "/client/auctions/show_auction/:id",
           
            meta: { breadcrumb: "تفاصيل المزاد" },
            component: function () {
              return import("../components/Client/AuctionDetails.vue");
            },
          },
          
          {
            name: "add-auction",
            path: "add-auction",
            redirect: "/client/auctions/add-auction/step1",
            meta: {
              breadcrumb: "اضافة مزاد",
              secured: true,
            },
            component: function () {
              return import("../views/AddAuctionView.vue");
            },
            children: [
              {
                name: "step1",
                path: "step1",
                meta: {
                  breadcrumb: "المعلومات العامة",
                },
                component: function () {
                  return import("../components/Client/AddSteps/Step1.vue");
                },
              },
              {
                name: "step2",
                path: "step2",
                meta: {
                  breadcrumb: "الوسائط",
                },
                component: function () {
                  return import("../components/Client/AddSteps/Step2.vue");
                },
              },
              {
                name: "step3",
                path: "step3",
                meta: {
                  breadcrumb: "قائمة المنتجات",
                },
                component: function () {
                  return import("../components/Client/AddSteps/Step3.vue");
                },
              },
              {
                name: "step4",
                path: "step4",
                meta: {
                  breadcrumb: "التواصل",
                },
                component: function () {
                  return import("../components/Client/AddSteps/Step4.vue");
                },
              },
            ],
          },
          {
            name: "edit-auction",
            path: "edit-auction/:id",
            redirect: (to) => ({
              path: "/client/auctions/edit-auction/" + to.params.id + "/step4", // user lang passed by :lang
            }),

            meta: {
              breadcrumb: "تعديل مزاد",
              secured: true,
            },
            component: function () {
              return import(
                "../components/Client/EditSteps/EditAuctionComponent.vue"
              );
            },
            children: [
              {
                name: "edit-step1",
                path: "step1",
                meta: {
                  breadcrumb: "المعلومات العامة",
                },
                component: function () {
                  return import("../components/Client/EditSteps/Step1.vue");
                },
              },
              {
                name: "edit-step2",
                path: "step2",
                meta: {
                  breadcrumb: "الوسائط",
                },
                component: function () {
                  return import("../components/Client/EditSteps/Step2.vue");
                },
              },
              {
                name: "edit-step3",
                path: "step3",
                meta: {
                  breadcrumb: "قائمة المنتجات",
                },
                component: function () {
                  return import("../components/Client/EditSteps/Step3.vue");
                },
              },
              {
                name: "edit-step4",
                path: "step4",
                meta: {
                  breadcrumb: "التواصل",
                },
                component: function () {
                  return import("../components/Client/EditSteps/Step4.vue");
                },
              },
            ],
          },
        ],
      },
      {
        path: "company-profile",
        name: "company-profile",
        meta: { breadcrumb: "الشركة" },
        component: function () {
          return import("../components/Client/CompanyInfoComponent.vue");
        },
      },

      {
        path: "client-profile",
        name: "client-profile",
        meta: { breadcrumb: "البروفايل" },
        component: function () {
          return import("../components/Client/ClientInfoComponent.vue");
        },
      },
    ],
  },
  {
    path: "/client_area",
    name: "client-area",
    meta: { showBar: false },
    component: function () {
      return import("../views/ClientAreaView.vue");
    },
    children: [
      {
        name: "client-account",
        path: "account",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/AccountComponent.vue");
        },
      },
      {
        name: "client-auctions",
        path: "auctions",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/AuctionsComponent.vue");
        },
      },

      {
        name: "client-notifications",
        path: "notifications",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/NotificationsComponent.vue");
        },
      },
      {
        name: "client-settings",
        path: "settings",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/SettingsComponent.vue");
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
}
const DEFAULT_TITLE = 'تصفية للمزادات';


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
    let token = store.getters["Auth/token"]
    if (token) {
      const jwtPayload = parseJwt(token);
      console.log(jwtPayload.exp)
        if (jwtPayload.exp < Date.now() / 1000) {
         
            // token expired

            store.commit("Auth/revokeUser");
            localStorage.removeItem("tag-to");
            localStorage.removeItem("tag-us");
            if (to.matched.some((record) => record.meta.secured)) {
              next({
                path: "/login",
                query: { redirect: to.fullPath },
              });
            }else next();
           

        }else next();
    
    }else next();
    
   
 
});

router.afterEach(() => {
  Vue.prototype.$loading(false);
});

export default router;
