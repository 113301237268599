<template>
<div class="bg-gray-100" id="app">

    <HeaderComponent v-if="showBar != false" />
    <!-- <NavBarComponent v-if="showBar != false"/> -->
    <router-view class="main-content" />
    <FooterComponent v-if="showBar != false" />

</div>
</template>

<script>
import HeaderComponent from '@/components/Home/HeaderComponent.vue'
import NavBarComponent from '@/components/NavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
    components: {
        HeaderComponent,
        NavBarComponent,
        FooterComponent,

    },

    computed: {
        showBar() {
            return this.$route.meta.showBar
        }
    },
    metaInfo() {
        return {
            title: "TASFIA AUCTIONS | تصفية للمزادات",
            meta: [{
                    name: "description",
                    content: "تقوم بتسهيل عملية بيع الأصول الثابتة والمنقولة",
                    id: "desc",
                },

                {
                    property: "og:description",
                    content: "تقوم بتسهيل عملية بيع الأصول الثابتة والمنقولة",
                },

                { property: "og:site_name", content: "TASFIA AUCTIONS | تصفية للمزادات" },
            ],
        };
    },
};
</script>

<style lang="scss">
#app {
    direction: rtl;

}

.vuetify-app {
    font-family: "Cairo", sans-serif;
}

.main-content {
    min-height: calc(100vh - 65.61px - 94.01px);
}
</style>
