// import Vue from 'vue';
import { plainAxiosInstance } from "../../main";
import router from "@/router";

const state = {
   auth_user: JSON.parse(localStorage.getItem("tag-us")),
   token: localStorage.getItem("tag-to"),
   loading: false,
   credentials: {},
   addValues: {},
   registerError: undefined,
   loginError: false,
};

const getters = {
   loading:       (state) => state.loading, 
   credentials:   (state) => state.credentials, 
   auth_user:     (state) => state.auth_user, 
   token:         (state) => state.token, 
   addValues:     (state) => state.addValues, 
   registerError: (state) => state.registerError, 
   loginError :   (state) => state.loginError
};

const actions = {
  async login({ commit, dispatch, state }, credentials) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("login", credentials)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            resolve(response)
            state.auth_user = response.data.user;
            state.token = response.data.access_token;
           
            localStorage.setItem("tag-us", JSON.stringify(response.data.user))
            localStorage.setItem("tag-to", response.data.access_token)
            state.loginError = false;
            dispatch(
              "Alert/successMsg",
              {
                msg: "تم تسجيل الدخول بنجاح",
              },
              {
                root: true,
              }
            );

           
            
          } else {
            state.loginError = true;
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          state.loginError = true;
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  

  async logout({ commit, dispatch, state }) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("logout")
        .then((response) => {
          state.auth_user = undefined;
          state.token = undefined;

          localStorage.removeItem("tag-to");
          localStorage.removeItem("tag-us");

          commit("setLoading", false);
          dispatch(
            "Alert/successMsg",
            {
              msg: "تم تسجيل الخروج بنجاح",
            },
            {
              root: true,
            }
          );

          resolve(response);
          router.push({ path: "/" }).then(() => {
            router.go(0);
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async register({ commit, dispatch, state }, formData) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("register", formData)
        .then((response) => {
          console.log(response.data);
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "تم تسجيل الدخول بنجاح",
              },
              {
                root: true,
              }
            );
          } else {
            state.registerError = response.data.data;
          }
        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async checkAuth({dispatch}){
    return new Promise((resolve, reject) => {
    plainAxiosInstance
    .post("check_auth")
    .then((response) => {
     
     
      resolve(response)
      if (!response.data.status) {
       
        localStorage.removeItem("tag-to");
        localStorage.removeItem("tag-us");
       
      } 
    })
    .catch((error) => {
      reject(error);
      dispatch("Alert/errorMsg", "", {
        root: true,
      });
    });
    
  });
  },


  sendEmailLink({ commit, dispatch, state }, credentials){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("password/email", credentials)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            resolve(response)
            state.loginError = false;
            dispatch(
              "Alert/successMsg",
              {
                msg: "تم ارسال بنجاح",
              },
              {
                root: true,
              }
            );

           
            
          } else {
            state.loginError = true;
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          state.loginError = true;
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },
  

  async sendOTP({ commit, dispatch, state }, credentials){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("send_otp", credentials)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            resolve(response)
            state.loginError = false;
            dispatch(
              "Alert/successMsg",
              {
                msg: "تم ارسال بنجاح",
              },
              {
                root: true,
              }
            );

           
            
          } else {
            state.loginError = true;
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          state.loginError = true;
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async update({ commit, dispatch, state }, formData) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("update_profile", formData)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status == "success") {
            localStorage.removeItem("tag-us");
            localStorage.setItem("tag-us", JSON.stringify(response.data.user))
            state.auth_user = response.data.user
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

 

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
};
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },


  setToken(state, payload) {
    console.log(`setting token ${token}`);
    state.token = payload.token;
  },
  setUser(state, payload) {
    console.log(`setting user ${payload}`);
    state.auth_user = payload.user;
  },

  revokeUser(state) {
    state.auth_user = undefined;
  },

  setAuth(){

  },

  revokeToken(state) {
    console.log(`revoking token`);
    state.token = undefined;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
